<template>
  <div>
    <v-row align="end" justify="end">
      <v-col cols="12" sm="12" md="6" lg="4">
        <el-input
          ref="searchBar"
          suffix-icon="el-icon-search"
          v-model="search"
          clearable
          placeholder="Search"
        >
          <template slot="prepend">
            <!-- <el-button @click="createOrganisation()"><i class="el-icon-plus"></i></el-button> -->
            <v-btn text @click="createSharedModel()"
              ><v-icon>add</v-icon></v-btn
            >
          </template>
        </el-input>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          dense
          fixed-header
          hide-default-footer
          :loading="loading"
          :items="sharedModels"
          :headers="headers"
          :search="search"
          height="65vh"
          style="cursor: pointer"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mr-1" @click="editItem(item)" small icon
              ><v-icon small>edit</v-icon></v-btn
            >
            <v-btn @click="deleteItem(item)" small icon color="danger"
              ><v-icon small>delete</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.availability`]="{ item }">
            <v-chip outlined  small color="success" v-if="item.container" class="mr-1">Allowed for Containers</v-chip>
            <v-chip outlined small color="success" v-if="item.shipment" class="mr-1">Allowed for Shipments</v-chip>
            <v-chip outlined small v-if="!item.container&&!item.shipment" color="primary">Globally Available</v-chip>

          </template>
          <!-- <template v-slot:[`item.isActive`]="{ item }">
                    <v-chip class="ma-2" label @click="changeActiveStatus(item)" :color="item.isActive ? 'primary' : 'danger'">{{item.isActive?'Active': 'Inactive'}}</v-chip>
                </template>
               -->
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modal"
      persistent
      width="750px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Model</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              :loading="saving"
              @click="saveModel"
              :disabled="!item.friendlyName || !item.name"
              >Submit</v-btn
            >
          <v-btn text @click="(modal = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            :items="models"
            item-text="name"
            item-value="name"
            v-model="item.name"
            outlined
            dense
            prepend-inner-icon="search"
            label="Attached Model"
          ></v-autocomplete>
          <v-row align="center">
            <v-col cols="12" sm="12" md="9">
              <v-text-field
            v-model="item.friendlyName"
            outlined
            dense
            label="Friendly Name"
          ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-checkbox class="my-0 py-0" dense v-model="item.shipment" label="Limit to Shipment"></v-checkbox>
              <v-checkbox class="my-0 py-0" dense v-model="item.container" label="Limit to Container"></v-checkbox>
            </v-col>
          </v-row>
         
          <v-list subheader dense v-if="item.name">
            <v-subheader
              >Available Fields
              <v-btn icon color="primary" @click="addField()"
                ><v-icon>add_circle_outline</v-icon></v-btn
              ></v-subheader
            >
            <v-list style="max-height: 40vh; overflow-y:auto">
              <v-list-item
              v-for="(field, index) in item.sharedModelFields"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ field.friendlyName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ field.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-btn small icon @click="copy(item, field)"
                    ><v-icon small>content_copy</v-icon></v-btn
                  >
                  <v-btn small icon @click="editField(field)"
                    ><v-icon small>edit</v-icon></v-btn
                  >
                  <v-btn color="danger" small icon @click="removeField(field, index)"
                    ><v-icon small>delete</v-icon></v-btn
                  >
                </v-row>
              </v-list-item-action>
            </v-list-item>
            </v-list>
           
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fieldModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Field</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(fieldModal = false), (fieldItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            :items="availableFields"
            v-model="fieldItem.name"
            outlined
            dense
            label="Field"
          ></v-autocomplete>
          <v-text-field
            outlined
            dense
            v-model="fieldItem.friendlyName"
            label="Friendly Name"
          ></v-text-field>
          <v-row class="mt-2" justify="center">
            <v-btn
              text
              color="primary"
              @click="saveField"
              :disabled="!fieldItem.friendlyName || !fieldItem.name"
              >Submit</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    availableFields: [],
    headers: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
      },
      {
        text: "Model",
        value: "name",
        align: "center",
      },
      {
        text: "Friendly Name",
        value: "friendlyName",
        align: "center",
      },
      {
        text: "Availability",
        value: "availability",
        align: "center",
      },
    ],
    fieldModal: false,
    fieldItem: {},
    item: {},
    loading: false,
    modal: false,
    models: [],
    saving: false,
    search: null,
    searchField: null,
    sharedModels: [],
    selectedFields: [],
  }),
  watch: {
    "item.name": {
      immediate: true,
      handler(val) {
        if (val) {
          if (!this.item.id) this.item.friendlyName = val;
          this.availableFields = this.models.find((m) => m.name === val).fields;
        } else {
          this.availableFields = [];
        }
      },
    },
    "fieldItem.name": {
      immediate: true,
      handler(val) {
        if (val) {
          if (!this.fieldItem.id&&!this.fieldItem.friendlyName) this.fieldItem.friendlyName = val;
        }
      },
    },
  },
  computed: {
    filterFields() {
      let result = this.availableFields;
      if (this.searchField) {
        result = result.filter((x) =>
          x.toLowerCase().includes(this.searchField.toLowerCase())
        );
      }
      return result;
    },
  },
  async created() {
    this.load();
  },
  methods: {
    addField() {
      this.fieldItem = {
        modalType: "Add",
      };
      this.fieldModal = true;
    },
    createSharedModel() {
      this.item = {
        sharedModelFields: [],
      };
      this.modal = true;
    },
    copy(item, field){
                let text = '${'+item.friendlyName+'_'+field.friendlyName+'}'
                navigator.clipboard.writeText(text);  
    },
    deleteItem(item) {
      this.$confirm("Are you sure you want to delete this Model?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      })
        .then(async () => {
          await this.$API.updateSharedModel({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getModels();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    editField(item) {
      this.fieldItem = item;
      this.fieldModal = true;
    },
    editItem(item) {
      this.item = item;
      this.modal = true;
    },
    load() {
      this.getModels();
      this.getAllModels();
    },
    async getModels() {
      this.loading = true;
      this.sharedModels = await this.$API.getSharedModels();
      this.loading = false;
    },
    async getAllModels() {
      this.models = await this.$API.getModels();
    },
    async removeField(field, index) {
        if(field.id){
            await this.$API.updateSharedModelField({id: field.id, isActive: false, isDeleted: true});
        }
      this.item.sharedModelFields.splice(index, 1);
    },
    async saveField() {
        if(this.fieldItem.id){
            await this.$API.updateSharedModelField(this.fieldItem)
            let index = this.item.sharedModelFields.findIndex(x => x.id == this.fieldItem.id);
            this.item.sharedModelFields[index] = this.fieldItem;
        } else {
      this.item.sharedModelFields.push(this.fieldItem);
        }
      this.fieldModal = false;
      this.fieldItem = {};
    },
    async saveModel() {
        this.saving =true

      if (this.item.id) {
        await this.$API.updateSharedModel(this.item);
      } else {
        await this.$API.createSharedModel(this.item);
        this.getModels();
      }
      this.$message.success("Successfully saved!");
      this.modal = false;
      this.item = {};
        this.saving =false

    },
  },
};
</script>
